var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-card-title',[_vm._v(" Indice "),_c('v-spacer'),_c('v-text-field',{attrs:{"outlined":"","dense":"","append-icon":"mdi-magnify","label":"Cerca","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.works,"search":_vm.search,"expanded":_vm.expanded,"item-class":_vm.rowClass,"footer-props":_vm.footerProps,"item-key":"documentNumber","show-expand":_vm.tableSettings.showExpand,"single-expand":_vm.tableSettings.singleExpand,"items-per-page":_vm.tableSettings.itemsPage,"locale":"it-IT"},on:{"update:expanded":function($event){_vm.expanded=$event},"click:row":_vm.expandRow},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.date ? _vm.dateFormat(item.date) : '--')+" ")]}},{key:"item.btn",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"color":"primary","small":""},on:{"click":function($event){$event.preventDefault();return _vm.link(item.ident)}}},[_vm._v(" "+_vm._s(_vm.$t('basics.goToWork'))+" ")])]}},{key:"expanded-item",fn:function(ref){
var headers = ref.headers;
var item = ref.item;
return [_c('td',{staticClass:"expandable_td",attrs:{"colspan":headers.length}},[_c('v-container',{staticClass:"expandable_container"},[_vm._v(" "+_vm._s(item.abstract)+" ")])],1)]}}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }