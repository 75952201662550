<template>
  <v-card>
    <v-card-title>
      Indice
      <v-spacer></v-spacer>
      <v-text-field
        v-model="search"
        outlined
        dense
        append-icon="mdi-magnify"
        label="Cerca"
        hide-details
      ></v-text-field>
    </v-card-title>
    <v-data-table
      :headers="headers"
      :items="works"
      :search="search"
      :expanded.sync="expanded"
      :item-class="rowClass"
      :footer-props="footerProps"
      item-key="documentNumber"
      :show-expand="tableSettings.showExpand"
      :single-expand="tableSettings.singleExpand"
      :items-per-page="tableSettings.itemsPage"
      locale="it-IT"
      @click:row="expandRow"
    >
    <template v-slot:[`item.date`]="{ item }">
        {{ item.date ? dateFormat(item.date) : '--'}}
      </template>
      <template v-slot:[`item.btn`]="{ item }">
        <v-btn @click.prevent="link(item.ident)" color="primary" small>
          {{ $t('basics.goToWork') }}
        </v-btn>
      </template>
      <template v-slot:expanded-item="{ headers, item }">
        <td class="expandable_td" :colspan="headers.length">
          <v-container class="expandable_container">
            {{ item.abstract }}
          </v-container>
        </td>
      </template>
    </v-data-table>
  </v-card>
</template>

<script>
export default {
  props: ["works"],
  data() {
    return {
      footerProps: {
        showFirstLastPage: true,
        itemsPerPageOptions: [5, 10, 15, 25, -1],          
      },
      tableSettings: {
        itemsPage: 15,
        singleExpand: true,
        showExpand: true,
      },
      expanded: [],
      search: "",
      headers: [
        { text: "", value: "data-table-expand" },
        { text: "ID", value: "documentNumber", width:'6em'},
        {
          text: "Titolo",                    
          width: "70%",
          align:"start",
          value: "title",
        },
        {            
            text: "Data",
            width: "10em",
            value: 'date',           
        },        
        { text: "", value: "btn", align: "end", sortable:false },
      ],
    };
  },
  methods: {
    async link(ident) {
      // save new browsing position
      await this.$store.dispatch("changeBrowisingState", this.$route.path);
      return this.$router.push(
        `/edition/browse/work/${ident.replace(/_/g, "")}`
      ); //_${this.work._id}
    },
    rowClass() {
      const rowClass = "rowTable";
      return rowClass;
    },
    dateFormat(date){
        let split = date.split('-');
        let newDate;
        switch (split.length) {
            case 1: 
                newDate = `${split[0]}`
                break;            
            case 2: 
                newDate = `${split[1]}/${split[0]}`
                break;            
            case 3:
                newDate = `${split[2]}/${split[1]}/${split[0]}`
                break;            
        }
        return newDate;
    },
    expandRow(_, { expand, isExpanded }) {        
      return expand(!isExpanded);
    },
  },
};
</script>

<style lang="scss">
@import "@/styles/variables.scss";

.rowTable:hover {
  cursor: pointer;
}

.expandable_td {
  background-color: white;
}

.expandable_container {  
  border: 3px solid white;
  background-color: white;
  font-style: italic;
  max-height: 12em;
  overflow: auto;
}
</style>